// import js dependencies

//swiper js
import Swiper from 'swiper/bundle';
//swiper css
import 'swiper/css/bundle';

jQuery(function($) {

	//detect vh of mobile
	function setElementHeight() {
		var vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', vh + 'px');
	}
	  
	// Call the function initially
	setElementHeight();
	
	// Listen for window resize events and call the function
	window.addEventListener('resize', setElementHeight);


	const latestSlider = document.querySelectorAll('.latestSlider-wrapper');
	var i = 0;

	for( i=0; i< latestSlider.length; i++ ) {

		var first = latestSlider[i].nextElementSibling;
		var second = first.nextElementSibling;
		var third = second.nextElementSibling;

		latestSlider[i].classList.add('latestSlider-wrapper-' + i); //slider
		first.classList.add('swiper-pagination-' + i); //pagination
		second.classList.add('latestSlider-button-next-' + i); //next
		third.classList.add('latestSlider-button-prev-' + i); //previous

		var slider = new Swiper('.latestSlider-wrapper-' + i, {
			speed: 1000,
			slidesPerView: 2,
			spaceBetween: 30,
			direction: 'horizontal',
			loop: true,
			autoplay: false,
			
			navigation: {
				nextEl: '.latestSlider-button-next-' + i,
				prevEl: '.latestSlider-button-prev-' + i,
			},
			breakpoints: {
				991: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
				300: {
					slidesPerView: 1,
					spaceBetween: 30,
				},
			},
		});

	}




	//disable scroll script
	var lastWindowScrollTop = 0,

	userAgent = navigator.userAgent,
	$body = $('body'),
	isIOS = /iPhone|iPad/.test(userAgent),
	NO_SCROLL_CLASS = isIOS
		? 'ios-noscroll'
		: 'non-ios-noscroll';

	function fixedBody() {
		if (isIOS) {
			lastWindowScrollTop = $(window).scrollTop();
			$body.addClass(NO_SCROLL_CLASS);
			$body.css('top', '-' + lastWindowScrollTop + 'px');
		} else {
			$body.addClass(NO_SCROLL_CLASS);
		}
	}

	function looseBody() {
		$body.removeClass(NO_SCROLL_CLASS);
		if (isIOS) {
			$body.css('top', '');
			window.scrollTo(0, lastWindowScrollTop);
		}
	}

	$.fn.scrollableOverlay = function () {
		this.on('show', fixedBody)
		this.on('hide', looseBody)
	};





	//menu
	$('#menuPage').scrollableOverlay()

	$('body').on('click', '.burger', function(event){
		$('#menuPage').toggleClass('mobileOpen');
		$('#page').toggleClass('mobileOpen');

		$(this).toggleClass('active');

		if($(this).hasClass('active')){
			// $('#menuPage').removeClass('hidden');
			$('#menuPage').trigger('show')
		}else{
			// $('#menuPage').addClass('hidden');
			$('#menuPage').trigger('hide')
		}
		
	});



	//close menu for desktop if resize is bigger than breakpoint
	$(window).on('resize', function(){
		var win = $(this); //this = window

		if (win.width() > 1200) {
			
			$('.menu .content.open').hide();

			$('#page').removeClass('mobileOpen');
			$('.menu .content').removeClass('open');
			$('.menuContainer').removeClass('active');
			$('.menuContainer .menu').removeClass('show');

			$('.mobileMenu').removeClass('open');
			
		}
    })


	$('body').on('click', '.scrollDown', function(event){
		event.preventDefault();
		var windowHeight = $('#topSection').height();
  		$('html, body').animate({ scrollTop: windowHeight }, 500);

	});


	$('body').on('click', '.tessellateConsent', function(event){
		event.preventDefault();
		$('.cs-info-sticky-button').click();
	});
	
});